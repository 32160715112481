@layer base {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333b48;
    border-radius: 100vh;
    border: 3px solid #333b48;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #333b48;
  }

  input {
    background: none;
    width: 12.5rem;
  }

  /* Remove arrows/spinner at text field */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Remote "choose file" button */
  input#file {
    display: inline-block;
    width: 100%;
    padding: 120px 0 0 0;
    height: 100px;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png") center
      center no-repeat #e4e4e4;
    border-radius: 20px;
    background-size: 60px 60px;
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: rgb(4 34 65) !important;
}

li.react-datepicker__time-list-item,
.react-datepicker__header,
.react-datepicker-time__header {
  color: white !important;
  font-family: "Cera Pro";
}

li.react-datepicker__time-list-item--disabled {
  display: none;
}

ul.react-datepicker__time-list {
  background-color: rgb(4 34 65) !important;
}

li.react-datepicker__time-list-item {
  background-color: rgb(3 22 41) !important;
}

li.react-datepicker__time-list-item:hover {
  background-color: rgb(51 59 72) !important;
}

li.react-datepicker__time-list-item--selected {
  background-color: rgb(0 103 255) !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.intl-tel-input.allow-dropdown .selected-flag {
  width: 77px !important;
  height: 97% !important;
  bottom: -1px;
  left: 1px;
  background: #47505d;
  border-radius: 4px;
}

.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-left: 96px !important;
}

.intl-tel-input.allow-dropdown .country-list {
  z-index: 1000;
  color: white;
  border: none;
  border-radius: 6px;
  background-color: rgb(3 22 41);
}

.intl-tel-input.allow-dropdown .country-list .country:hover {
  background-color: rgb(51 59 72);
}

.intl-tel-input.allow-dropdown .country-list .divider {
  border-color: rgb(51 59 72);
}

.collectible-airdrop {
  width: 350px;
  max-width: 100%;
  margin: auto;
}

html,
body {
  background-color: #040520;
  color: #d1d6db;
  background-repeat: no-repeat;
  height: 100%;
}

#root {
  height: 100%
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 32px rgb(51 59 72) inset !important;
  -webkit-box-shadow: 0 0 0 32px rgb(51 59 72) #47505d inset !important;
  -webkit-text-fill-color: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
